import type React from 'react'
import { PropsWithChildren } from 'react'

import { Button, Drawer, DrawerTrigger, Text } from '@grupoboticario/flora-react'
import { RoundCircleClockIcon } from '@grupoboticario/flora-react-icons'

import { ptBR } from '../locales'

import { useEvents } from '@shared/events'
import { useIsMobile } from '@shared/hooks'
import {
  DrawerBodyStyled,
  DrawerCloseButtonStyled,
  DrawerContentStyled,
  DrawerHeaderStyled,
} from './tabulation-drawer.styles'

interface TabulationDrawerProps extends PropsWithChildren {
  disabled?: boolean
  reId?: string
  attendanceId?: string
}

export const TabulationDrawer: React.FC<TabulationDrawerProps> = ({
  children,
  disabled,
  attendanceId,
  reId,
}) => {
  const events = useEvents()
  const isMobile = useIsMobile()

  return (
    <Drawer>
      <DrawerContentStyled>
        <DrawerHeaderStyled>
          <Text size="subTitleDeskMedium" weight="medium">
            {ptBR.tabulationHistory}
          </Text>
          <DrawerCloseButtonStyled />
        </DrawerHeaderStyled>

        <DrawerBodyStyled>{children}</DrawerBodyStyled>
      </DrawerContentStyled>

      <DrawerTrigger disabled={disabled} css={{ marginTop: '$2', '@mobile': { margin: 0 } }}>
        <Button
          hierarchy="secondary"
          onClick={() => events.viewHistory(reId, attendanceId)}
          size={isMobile ? 'medium' : 'small'}
          has="iconLeft"
          icon={<RoundCircleClockIcon />}
          isFull={isMobile}
        >
          {ptBR.tabulationHistoryAll}
        </Button>
      </DrawerTrigger>
    </Drawer>
  )
}
