import type React from 'react'

import { FORM_TAB_ID } from '@shared/consts'

import { FormContainer } from './form-tabulation.styles'
import { useFormTabulation } from './hooks'
import { FormFields, FormResellerInfo, FormStatus, ReasonsProvider } from './modules'

interface FormTabulationProps {
  onFinaly: () => void
}

export const FormTabulation: React.FC<FormTabulationProps> = ({ onFinaly }) => (
  <ReasonsProvider>
    <Form onFinaly={onFinaly} />
  </ReasonsProvider>
)

const Form: React.FC<FormTabulationProps> = ({ onFinaly }) => {
  const { onSubmit, filled, error, loading, errorType } = useFormTabulation()
  const showform = !filled && !loading

  return (
    <FormContainer
      id={FORM_TAB_ID}
      data-testid={FORM_TAB_ID}
      onSubmit={onSubmit}
      center={filled || loading}
      noValidate
    >
      <FormStatus
        loading={loading}
        filled={filled}
        error={error}
        errorType={errorType}
        onFinaly={onFinaly}
        onRetry={onSubmit}
      />
      {showform && <FormResellerInfo />}
      <FormFields showFields={showform} />
    </FormContainer>
  )
}
