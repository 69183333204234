import { PropsWithChildren, createContext } from 'react'

import { type API } from './api.types'

export const ApiContext = createContext<API | null>(null)

interface ApiProviderProps extends PropsWithChildren {
  api: API
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children, api }) => (
  <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
)
