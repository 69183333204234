import { useCallback, useEffect, useRef, useState } from 'react'

import { createApi, MetaState, type AsyncStatus } from '@shared/api'
import { type TabulationConfigApiHistory } from '@shared/configuration'

import { useEvents } from '@shared/events'
import { useIsMounted } from '@shared/hooks'
import { type GetTabulationHistoryDTO, type Tabulation } from './tabulation-history.types'

interface UseTabulationHistory extends AsyncStatus {
  items: Tabulation[]
  getNextPage: () => void
  getCurrentPage: () => void
  isEmpty: boolean
}

interface TabulationHistoryState {
  items: Tabulation[]
  status: MetaState
  hasMore: boolean
}

export const useTabulationHistory = (config: TabulationConfigApiHistory): UseTabulationHistory => {
  const [history, setHistory] = useState<TabulationHistoryState>({
    status: MetaState.Idle,
    hasMore: true,
    items: [],
  })

  const events = useEvents()
  const { current: api } = useRef(createApi(config))
  const ref = useRef({ page: 1, limit: 5 })
  const isMounted = useIsMounted()

  const loading = history.status === MetaState.Loading
  const error = history.status === MetaState.Error

  const getTabulationHistory = useCallback(
    async (page: number, limit: number) => {
      setHistory((t) => ({ ...t, status: MetaState.Loading }))

      const result = await api.getTabulations<GetTabulationHistoryDTO>(page, limit)

      if (!isMounted()) return

      if (result.ok) {
        const data = result.value.data
        const totalPages = result.value.meta.totalPages

        setHistory(({ items }) => ({
          items: items.concat(data),
          status: MetaState.Fullfilled,
          hasMore: page < totalPages,
        }))
      } else {
        const status = result.code === 404 ? MetaState.Fullfilled : MetaState.Error
        setHistory((t) => ({ ...t, status }))
      }
    },
    [api, isMounted],
  )

  const getCurrentPage = useCallback(() => {
    void getTabulationHistory(ref.current.page, ref.current.limit)
  }, [getTabulationHistory])

  const getNextPage = useCallback(() => {
    if (!history.hasMore || loading) return

    if (!error) ref.current.page += 1
    events.paginateHistory(ref.current.page)
    getCurrentPage()
  }, [history.hasMore, loading, error, getCurrentPage, events])

  useEffect(() => {
    getCurrentPage()
  }, [getCurrentPage])

  return {
    getCurrentPage,
    getNextPage,
    loading,
    error,
    items: history.items,
    filled: history.status === MetaState.Fullfilled,
    isEmpty: history.status === MetaState.Fullfilled && history.items.length === 0,
  }
}
