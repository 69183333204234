import { type FormFieldTypes } from '../form-fields.types'

export const useFieldProps = <T extends FormFieldTypes>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formFieldType,
  ...props
}: T): Omit<T, 'formFieldType'> => {
  const isVisible = props.isVisible ?? true
  const isRequired = props.isRequired && !props.disabled && isVisible

  return {
    ...props,
    isVisible,
    isRequired,
  }
}
