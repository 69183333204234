import { useContext } from 'react'

import { Context } from './configuration.provider'
import { type TabulationConfig } from './configuration.types'

export const useTabulationConfig = (): TabulationConfig => {
  const context = useContext(Context)
  if (!context) throw new Error('No context provided')
  return context
}
