import { styled } from '@grupoboticario/flora-react'

export const List = styled('ul', {
  listStyle: 'none',
  padding: '0',
})

export const ListItem = styled('li', {
  padding: '$4 0',

  variants: {
    divider: {
      true: {
        borderBottom: '1px solid $nonInteractiveAltAuxiliar',
      },
      false: {},
    },
  },
})
