import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react'

import { MetaState, useApi } from '@shared/api'
import { useFormStatus } from '@shared/form-provider'
import { useIsMounted } from '@shared/hooks'

import {
  type FormFieldCondition,
  type FormFieldTypes,
  type GetFormFieldsDTO,
} from '../form-fields.types'

interface UseFormField {
  fields: FormFieldTypes[]
  setFields: Dispatch<SetStateAction<FormFieldTypes[]>>
  conditions: FormFieldCondition[]
}

export const useFormFields = (): UseFormField => {
  const [fields, setFields] = useState<FormFieldTypes[]>([])
  const [conditions, setConditions] = useState<FormFieldCondition[]>([])

  const [, setFormStatus] = useFormStatus()
  const isMounted = useIsMounted()
  const api = useApi()

  const getFields = useCallback(async () => {
    setFormStatus(MetaState.Loading)
    const result = await api.getFormFields<GetFormFieldsDTO>()

    if (!isMounted()) return

    if (result.ok) {
      const { conditions = [], formFields } = result.value

      const mappedFormFields = formFields.map(({ isInitiallyDisabled, placeHolder, ...f }) => ({
        ...f,
        disabled: isInitiallyDisabled,
        placeholder: placeHolder,
      }))

      setFields(mappedFormFields)
      setConditions(conditions)
    }

    setFormStatus(MetaState.Idle)
  }, [api, isMounted, setFormStatus])

  useEffect(() => {
    void getFields()
  }, [getFields])

  return { fields, conditions, setFields }
}
