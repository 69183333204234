import { type FormFieldOption } from '../form-fields.types'

export type ReasonOptionDTO = ReasonOption[]

export interface ReasonOption {
  id: string
  reason: string
  subreasons: SubreasonOption[]
}

export interface SubreasonOption {
  id: string
  code: string
  subreason: string
}

export type FormFieldOptionDict = Record<string, FormFieldOption[]>

export enum ReasonFieldType {
  reason = 'reason',
  subreason = 'subreason',
}
