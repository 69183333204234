import { styled } from '@grupoboticario/flora-react'

export const FormFieldsContainer = styled('div', {
  flexDirection: 'column',
  padding: '0',
  justifyContent: 'center',
  flex: '1',
  gap: '$2',

  '@mobile': {
    padding: '0 $4',
  },

  variants: {
    show: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },
})
