import { type TabulationConfig } from './configuration.types'

export const INITIAL_CONFIG: TabulationConfig = {
  header: {
    title: '',
  },
  footer: {
    cancelButtonLabel: '',
    confirmButtonLabel: '',
  },
  status: {
    closeOnSuccess: true,
    errorDescription: '',
    loadingDescription: '',
    loadingTitle: '',
  },
  form: {
    reCodeLabel: '',
    reNameLabel: '',
    reCode: '',
    reName: '',
    requiredMessage: '',
  },
  api: {
    baseUrl: '',
    sendTabulationEndpoint: '',
    formFieldsEndpoint: '',
    reasonsEndpoint: '',
    timeout: 15000,
  },
}
