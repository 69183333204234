import { Flex, Spinner } from '@grupoboticario/flora-react'

import { type GetLabelTab, type Tabulation } from '../tabulation-history.types'
import { TabulationItem } from '../tabulation-item'

import { List, ListItem } from './tabulation-list.styles'

interface TabulationListProps {
  items: Tabulation[]
  loading: boolean
  hideContactPurpose?: boolean
  getLabelTab?: GetLabelTab
}

const TabulationList = ({
  items,
  loading,
  hideContactPurpose,
  getLabelTab,
}: TabulationListProps): JSX.Element => {
  const lastIndex = items.length - 1

  return (
    <List>
      {items.map((item, index) => (
        <ListItem divider={index < lastIndex} key={index}>
          <TabulationItem
            item={item}
            hideContactPurpose={hideContactPurpose}
            getLabelTab={getLabelTab}
          />
        </ListItem>
      ))}

      {loading && (
        <ListItem data-testid="tab:list-loading">
          <Flex justify="center" align="center">
            <Spinner size="md" color="$backgroundAltPrimary" />
          </Flex>
        </ListItem>
      )}
    </List>
  )
}

export { TabulationList }
