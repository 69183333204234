import { Button as ButtonFlora, ModalFooter, styled } from '@grupoboticario/flora-react'

const Container = styled(ModalFooter, {
  position: 'relative',
  gap: '$4',

  '@mobile': {
    '&::before': {
      content: '',
      position: 'absolute',
      right: '$5',
      left: '$5',
      top: 0,
      height: '1px',
      backgroundColor: '$nonInteractiveAltAuxiliar',
    },
  },
})

const StyledButton = styled(ButtonFlora, {
  minWidth: '90px',
})

export { Container, StyledButton }
