import type React from 'react'

import { FloraProvider } from '@grupoboticario/flora-react'

import { ScrollArea } from '@shared/components'
import { type TabulationConfigApiHistory } from '@shared/configuration'

import { ptBR } from './locales'
import { TabulationDrawer } from './tabulation-drawer'
import { useTabulationHistory } from './tabulation-history.hooks'
import { TabulationHistoryContainer } from './tabulation-history.styles'
import { type GetLabelTab } from './tabulation-history.types'
import { TabulationItem } from './tabulation-item'
import { TabulationList } from './tabulation-list'
import { TabulationStatus } from './tabulation-status'

export interface TabulationHistoryProps {
  apiConfig: TabulationConfigApiHistory
  hideContactPurpose?: boolean
  getLabelTab?: GetLabelTab
  reId?: string
  attendanceId?: string
}

export const TabulationHistory: React.FC<TabulationHistoryProps> = ({
  hideContactPurpose,
  attendanceId,
  getLabelTab,
  apiConfig,
  reId,
}) => {
  const { getCurrentPage, getNextPage, isEmpty, loading, filled, error, items } =
    useTabulationHistory(apiConfig)

  const [lastTabulation] = items

  const showTabItem = filled || (loading && lastTabulation)
  const showTabItemText = Boolean(lastTabulation) || error || loading
  const disableTabDrawerButton = isEmpty || error || loading

  const headerText = showTabItemText ? ptBR.lastTabulationIn : ptBR.lasTabulationHasNo

  const headerRight = (
    <TabulationDrawer disabled={disableTabDrawerButton} reId={reId} attendanceId={attendanceId}>
      <ScrollArea onEndScroll={getNextPage}>
        <TabulationList
          items={items}
          loading={loading}
          hideContactPurpose={hideContactPurpose}
          getLabelTab={getLabelTab}
        />
      </ScrollArea>
    </TabulationDrawer>
  )

  return (
    <FloraProvider>
      <TabulationHistoryContainer>
        {showTabItem && (
          <TabulationItem
            bg="$backgroundPrimary"
            item={lastTabulation}
            headerText={headerText}
            headerRight={headerRight}
            hideContactPurpose={hideContactPurpose}
            getLabelTab={getLabelTab}
          />
        )}

        {!showTabItem && (
          <TabulationStatus onRetry={getCurrentPage} loading={loading} error={error} />
        )}
      </TabulationHistoryContainer>
    </FloraProvider>
  )
}
