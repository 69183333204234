import { styled } from '@grupoboticario/flora-react'

export const TabulationHistoryContainer = styled('section', {
  overflow: 'hidden',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  minHeight: 350,
})
