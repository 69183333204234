import { styled, Text } from '@grupoboticario/flora-react'

const InfoContainer = styled('div', {
  backgroundColor: '$backgroundSecondary',
  borderRadius: '$medium',
  display: 'flex',
  padding: '$4',
  gap: '$6',

  '*': {
    margin: 0,
  },
})

const InfoGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',

  variants: {
    fill: {
      true: {
        flex: 1,
      },
    },
  },

  '&:first-of-type': {
    paddingRight: '$4',
  },
})

const Title = styled(Text, {
  fontSize: '$bodySmall',
  padding: '0 $4',
})

export { InfoContainer, InfoGroup, Title }
