import type React from 'react'
import { Fragment } from 'react'

import { ControlledCheckbox } from './checkbox'
import { ControlledSelectField } from './select-field'
import { ControlledTextField } from './text-field'

import { FormFieldsContainer } from './form-fields.styles'
import { FieldType, type FormFieldOptions } from './form-fields.types'
import { useConditions, useFormFields } from './hooks'
import { ControlledRadioButton } from './radio-button'
import { ControlledRadioGroup } from './radio-group'
import { ControlledReasonField, ControlledSubreasonField, ReasonFieldType } from './reason-field'

interface FormFieldsProps {
  showFields: boolean
}

export const FormFields: React.FC<FormFieldsProps> = ({ showFields }) => {
  const { conditions, fields, setFields } = useFormFields()
  const { onFieldChange } = useConditions(fields, setFields, conditions)

  return (
    <FormFieldsContainer show={showFields}>
      {fields.map((field) => {
        switch (field.formFieldType) {
          case FieldType.checkbox:
            return <ControlledCheckbox key={field.id} {...field} />
          case FieldType.radioButton:
            return <ControlledRadioButton key={field.id} onFieldChange={onFieldChange} {...field} />
          case FieldType.radio:
            return <ControlledRadioGroup key={field.id} onFieldChange={onFieldChange} {...field} />
          case FieldType.select:
            return <ControlledSelect key={field.id} {...field} />
          case FieldType.text:
            return <ControlledTextField key={field.id} as="input" {...field} />
          case FieldType.textarea:
            return <ControlledTextField key={field.id} as="textarea" {...field} />
          default:
            return <Fragment key={field.id} />
        }
      })}
    </FormFieldsContainer>
  )
}

const ControlledSelect: React.FC<FormFieldOptions> = (field) => {
  switch (field.name) {
    case ReasonFieldType.reason:
      return <ControlledReasonField {...field} />
    case ReasonFieldType.subreason:
      return <ControlledSubreasonField {...field} />
    default:
      return <ControlledSelectField {...field} />
  }
}
