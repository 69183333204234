import type React from 'react'
import { PropsWithChildren, createContext, useMemo, useState } from 'react'
import { FormProvider as HookFormProvider, useForm } from 'react-hook-form'

import { MetaState, type AsyncStatus } from '@shared/api'

export type FormStatusContextProps = [AsyncStatus, React.Dispatch<React.SetStateAction<MetaState>>]

export const FormStatusContext = createContext<FormStatusContextProps | null>(null)

export const FormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [status, setStatus] = useState(MetaState.Idle)

  const value = useMemo(
    () => ({
      error: status === MetaState.Error,
      filled: status === MetaState.Fullfilled,
      loading: status === MetaState.Loading,
    }),
    [status],
  )

  return (
    <FormStatusContext.Provider value={[value, setStatus]}>
      <HookFormProvider {...useForm()}>{children}</HookFormProvider>
    </FormStatusContext.Provider>
  )
}
