import { forwardRef, Fragment } from 'react'

import { Checkbox as CheckboxFlora, Flex, Text } from '@grupoboticario/flora-react'

import { FieldInfo } from '../field-info'
import { type FormFieldControl, type FormFieldDefault } from '../form-fields.types'

export interface CheckboxProps extends FormFieldDefault, FormFieldControl<boolean> {}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { id, isRequired, value, labelText, disabled, onChange, invalidText, isVisible, ...props },
    ref,
  ) => {
    const color = invalidText ? '$error-standard' : ''

    return (
      <Fragment>
        {isVisible && (
          <FieldInfo error={invalidText} disabled={disabled}>
            <Flex align="center" gap="$2">
              <CheckboxFlora
                id={id}
                ref={ref}
                css={{ color }}
                checked={value}
                disabled={disabled}
                required={isRequired}
                aria-invalid={Boolean(invalidText)}
                status={invalidText ? 'error' : 'neutral'}
                onChange={(e) =>
                  onChange({
                    target: { value: e.currentTarget.checked },
                    type: 'change',
                  })
                }
                {...props}
              />

              <Text
                as="label"
                size="bodyMediumShort"
                color={disabled ? '$nonInteractiveAuxiliar' : '$nonInteractivePredominant'}
                css={{ cursor: 'pointer' }}
                htmlFor={id}
              >
                {labelText}
              </Text>
            </Flex>
          </FieldInfo>
        )}
      </Fragment>
    )
  },
)

export { Checkbox }

Checkbox.displayName = 'Checkbox'
