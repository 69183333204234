import { ForwardedRef, forwardRef, Fragment } from 'react'

import { Input, Textarea } from '@grupoboticario/flora-react'

import { FieldInfo } from '../field-info'
import { type FormFieldControl, type FormFieldDefault } from '../form-fields.types'

interface TextFieldProps extends FormFieldDefault, FormFieldControl<string> {
  as?: 'textarea' | 'input'
}

const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextFieldProps>(
  (
    {
      as,
      isRequired,
      invalidText,
      value,
      isVisible,
      labelText,
      optionalText,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      placeholder,
      ...props
    },
    ref,
  ) => {
    const isTextArea = as === 'textarea'

    return (
      <Fragment>
        {isVisible && (
          <FieldInfo disabled={props.disabled} error={invalidText}>
            {isTextArea && (
              <Textarea
                ref={ref as ForwardedRef<HTMLTextAreaElement>}
                value={value}
                status={invalidText ? 'error' : 'neutral'}
                required={isRequired}
                label={labelText}
                helperText={optionalText}
                {...props}
              />
            )}
            {!isTextArea && (
              <Input
                ref={ref as ForwardedRef<HTMLInputElement>}
                value={value}
                status={invalidText ? 'error' : 'neutral'}
                required={isRequired}
                label={labelText}
                helperText={optionalText}
                {...props}
              />
            )}
          </FieldInfo>
        )}
      </Fragment>
    )
  },
)

export { TextField }

TextField.displayName = 'TextField'
