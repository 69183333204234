import { useController } from '@shared/form-provider'

import { type FormFieldOptions } from '../form-fields.types'
import { useFieldProps } from '../hooks'
import { SelectField } from './select-field'

export const ControlledSelectField: React.FC<FormFieldOptions> = (props) => {
  const fieldProps = useFieldProps(props)
  const controlled = useController(fieldProps.name, fieldProps.isRequired, '')
  return <SelectField {...fieldProps} {...controlled} />
}
