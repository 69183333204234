import { styled } from '@grupoboticario/flora-react'

export const TabulationItemContainer = styled('div', {
  padding: '$4',
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: '$medium',
  gap: '$4 0',

  '@mobile': {
    padding: '$4 $8',
  },
})

export const TabulationFieldGroup = styled('div', {
  overflow: 'hidden',
  display: 'flex',
  gap: '0 $4',
  flexBasis: '100%',
})

export const TabulationFieldContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  flex: 1,
  '& span': {
    marginBottom: '$2',
  },
  '& p': {
    margin: 0,
  },
})

export const TabulationItemHeaderContainer = styled('div', {
  padding: '0 $0 $3',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  '& h3': {
    margin: 0,
  },

  '@mobile': {
    padding: '0 $4 $4 $4',
  },
})
