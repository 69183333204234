import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'

import { useApi } from '@shared/api'
import { useIsMounted } from '@shared/hooks'

import { type FormFieldOption } from '../form-fields.types'
import {
  type FormFieldOptionDict,
  type ReasonOption,
  type ReasonOptionDTO,
  type SubreasonOption,
} from './reason-field.types'

export interface ReasonProviderProps {
  reasons: FormFieldOption[]
  subreasons: FormFieldOption[]
  initials: FormFieldOption[]
  setSubreasons: (reasonId: string) => void
  setReasons: Dispatch<SetStateAction<FormFieldOption[]>>
}

export const ReasonsContext = createContext<ReasonProviderProps | null>(null)

export const ReasonsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [reasons, setReasons] = useState<FormFieldOption[]>([])
  const [subreasons, setStateSubreasons] = useState<FormFieldOption[]>([])

  const subreasonsDictionary = useRef<FormFieldOptionDict>({})
  const reasonsInitial = useRef<FormFieldOption[]>([])

  const isMounted = useIsMounted()
  const api = useApi()

  const setInitialReasons = useCallback(
    (reasonList: FormFieldOption[], subreasonsDict: FormFieldOptionDict): void => {
      subreasonsDictionary.current = subreasonsDict
      reasonsInitial.current = reasonList
      setReasons(reasonList)
    },
    [],
  )

  const getReasons = useCallback(async () => {
    const result = await api.getReasons<ReasonOptionDTO>()

    if (result.ok && isMounted()) {
      setInitialReasons(...toFieldOption(result.value))
    }
  }, [api, isMounted, setInitialReasons])

  useEffect(() => {
    void getReasons()
  }, [getReasons])

  const setSubreasons = (reasonId: string): void => {
    setStateSubreasons(subreasonsDictionary.current[reasonId] ?? [])
  }

  const value = useMemo(
    () => ({
      initials: reasonsInitial.current,
      reasons,
      subreasons,
      setReasons,
      setSubreasons,
    }),
    [reasons, subreasons],
  )

  return <ReasonsContext.Provider value={value}>{children}</ReasonsContext.Provider>
}

type MappedToFieldOption = [FormFieldOption[], FormFieldOptionDict]

const toFieldOption = (reasons: ReasonOptionDTO): MappedToFieldOption => {
  const reasonList: MappedToFieldOption[0] = []
  const subreasonsDict: MappedToFieldOption[1] = {}

  for (const reason of reasons) {
    reasonList.push(reasonToOption(reason))

    const subreasons = []
    for (const srsn of reason.subreasons) {
      subreasons.push(subreasonToOption(srsn))
    }

    subreasonsDict[reason.id] = subreasons
  }

  return [reasonList, subreasonsDict]
}

const reasonToOption = (rsn: ReasonOption): FormFieldOption => ({
  value: rsn.id,
  label: rsn.reason,
  id: rsn.id,
})

const subreasonToOption = (subrsn: SubreasonOption): FormFieldOption => ({
  value: subrsn.id,
  label: subrsn.subreason,
  id: subrsn.id,
})
