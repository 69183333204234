import type React from 'react'
import { PropsWithChildren, createContext } from 'react'

import { createTabulationConfig } from '../tests/configuration.factory'

import { type TabulationConfig } from './configuration.types'

export const Context = createContext<TabulationConfig>(createTabulationConfig())

interface ConfigurationProvderProps extends PropsWithChildren {
  config: TabulationConfig
}

export const ConfigurationProvider: React.FC<ConfigurationProvderProps> = ({
  config,
  children,
}) => <Context.Provider value={config}>{children}</Context.Provider>
