import { useEffect, useState } from 'react'

import { MetaState, useApi } from '@shared/api'
import {
  useTabulationConfig,
  type TabulationForm,
  type TabulationResult,
} from '@shared/configuration'
import { useFormHandler, useFormStatus } from '@shared/form-provider'
import { useIsMounted } from '@shared/hooks'

import { TabulationErrorType } from '../form-tabulation.types'
import { useReasonProvider } from '../modules'

interface UseFormTabulation {
  onSubmit: (body: unknown) => Promise<void>
  loading: boolean
  filled: boolean
  error: boolean
  errorType: TabulationErrorType
}

export const useFormTabulation = (): UseFormTabulation => {
  const handler = useFormHandler()
  const [status, setFormStatus] = useFormStatus()
  const { status: statusConfig } = useTabulationConfig()
  const { reasons, subreasons } = useReasonProvider()
  const isMounted = useIsMounted()
  const api = useApi()

  const [errorType, setErrorType] = useState<TabulationErrorType>(TabulationErrorType.unknown)

  useEffect(() => {
    return () => {
      handler.changeValues()
      setFormStatus(MetaState.Idle)
    }
  }, [handler, setFormStatus])

  const onSubmit = async (tabulationRaw: TabulationForm): Promise<void> => {
    setFormStatus(MetaState.Loading)
    const tabulation = sanitizeTabulation(tabulationRaw)
    const result = await api.sendTabulation(tabulation)

    if (!isMounted()) return

    const tabresult = getTabulationResult(tabulation)

    if (result.ok) {
      setFormStatus(MetaState.Fullfilled)
      statusConfig.onTabulateSuccess?.({ ...tabresult, data: result.value })
    } else {
      handleError(result.code)
      setFormStatus(MetaState.Error)
      statusConfig.onTabulateError?.({ ...tabresult, error: result.error })
    }
  }

  const handleError = (code: number | null): void => {
    switch (code) {
      case 409:
        setErrorType(TabulationErrorType.closedBySystem)
        break
      case 410:
        setErrorType(TabulationErrorType.closed)
        break
      case 403:
        setErrorType(TabulationErrorType.notExists)
        break
      case 422:
        setErrorType(TabulationErrorType.expired)
        break
      default:
        setErrorType(TabulationErrorType.unknown)
    }
  }

  const getTabulationResult = (tabulation: TabulationForm): TabulationResult => {
    const reason = reasons.find((item) => item.value === tabulation.reason)?.label

    const subreason = subreasons.find((item) => item.value === tabulation.subreason)?.label

    return {
      reason,
      subreason,
      tabulation,
    }
  }

  const sanitizeTabulation = (body: TabulationForm): TabulationForm => {
    const sinatized: TabulationForm = {}

    for (const key in body) {
      const bodyValue = body[key]

      if (typeof bodyValue === 'boolean' || bodyValue) {
        sinatized[key] = bodyValue
      }
    }

    return sinatized
  }

  return {
    onSubmit: handler.handleSubmit(onSubmit),
    errorType,
    ...status,
  }
}
