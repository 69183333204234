import { useController } from '@shared/form-provider'

import { type FormFieldOptions } from '../form-fields.types'
import { useFieldProps } from '../hooks'
import { RadioGroup } from '../radio-group'

interface ControlledRadioGroupOptions extends FormFieldOptions {
  onFieldChange: (id: string, value: string) => void
}

export const ControlledRadioGroup: React.FC<ControlledRadioGroupOptions> = ({
  onFieldChange,
  ...props
}) => {
  const defaultValue = props.fieldOptions.find((o) => o.default)?.value
  const fieldProps = useFieldProps(props)
  const controlled = useController(fieldProps.name, fieldProps.isRequired, defaultValue)

  return (
    <RadioGroup
      {...fieldProps}
      {...controlled}
      onChange={(event) => {
        controlled.onChange(event)
        onFieldChange(props.id, event.target.value)
      }}
    />
  )
}
