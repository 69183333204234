import type React from 'react'
import { forwardRef, Fragment } from 'react'

import { Flex, Radio, RadioGroup as RadioGroupFlora, Text } from '@grupoboticario/flora-react'

import { FieldInfo } from '../field-info'
import {
  type FormFieldControl,
  type FormFieldOption,
  type FormFieldOptions,
} from '../form-fields.types'

import { useIsMobile } from '@shared/hooks'
import { Label, RadioItemContainer } from './radio-button.styles'

export interface RadioButtonProps extends FormFieldOptions, FormFieldControl<string> {}

export const RadioButton = forwardRef<HTMLDivElement, RadioButtonProps>(
  (
    {
      id,
      name,
      labelText,
      fieldOptions,
      isRequired,
      onChange,
      disabled,
      invalidText,
      isVisible,
      ...props
    },
    ref,
  ) => {
    const isMobile = useIsMobile()

    return (
      <Fragment>
        {isVisible && (
          <FieldInfo error={invalidText} disabled={disabled} title={labelText}>
            <RadioGroupFlora
              id={id}
              ref={ref}
              name={name}
              required={isRequired}
              onValueChange={(value) => onChange({ target: { value }, type: 'change' })}
              {...props}
            >
              <Flex
                wrap="wrap"
                align={isMobile ? 'stretch' : 'center'}
                gap="$2"
                direction={isMobile ? 'column' : 'row'}
              >
                {fieldOptions.map((opt, idx) => (
                  <RadioItem
                    key={idx}
                    disabled={disabled}
                    invalid={Boolean(invalidText)}
                    selected={props.value === opt.value}
                    {...opt}
                    id={id}
                  />
                ))}
              </Flex>
            </RadioGroupFlora>
          </FieldInfo>
        )}
      </Fragment>
    )
  },
)

RadioButton.displayName = 'RadioButton'

interface RadioItemProps extends FormFieldOption {
  id: string
  invalid: boolean
  selected: boolean
}

const RadioItem: React.FC<RadioItemProps> = ({ label, disabled, value, id, invalid, selected }) => {
  const fieldId = `${id}-${value}`

  return (
    <RadioItemContainer>
      <Radio id={fieldId} aria-invalid={invalid} disabled={disabled} value={value} />

      <Label htmlFor={fieldId} invalid={invalid} disabled={disabled} selected={selected}>
        <Text as="span" color="$actionableDefault" size="bodySmallStandardRegular" weight="regular">
          {label}
        </Text>
      </Label>
    </RadioItemContainer>
  )
}
