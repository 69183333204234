import { useEffect, useState } from 'react'

import { INITIAL_CONFIG, type TabulationConfig } from '@shared/configuration'

import { renderModalRoot } from './modal-tabulation'

interface UseModalTabulationConfig {
  config: ModalTabulationConfig
  onOpenChange: () => void
}

type ModalEvent = CustomEvent<ModalTabulationConfig>

interface ModalTabulationConfig extends TabulationConfig {
  open: boolean
}

export function useModalTabulationConfig(): UseModalTabulationConfig {
  const [config, setConfig] = useState(INITIAL_CONFIG as ModalTabulationConfig)

  useEffect(() => {
    const eventCallback = (ev: ModalEvent): void => setConfig(ev.detail)
    window.addEventListener(TAB_EVENT, eventCallback)

    return () => {
      window.removeEventListener(TAB_EVENT, eventCallback)
    }
  }, [])

  return {
    config,
    onOpenChange() {
      setConfig((c) => ({ ...c, open: !c.open }))
    },
  }
}

interface UseModalTabulation {
  open: () => void
  close: () => void
}

export function useModalTabulation(config: TabulationConfig): UseModalTabulation {
  useEffect(renderModalRoot, [])

  return {
    open() {
      window.dispatchEvent(
        new CustomEvent(TAB_EVENT, {
          detail: { ...config, open: true },
        }),
      )
    },
    close() {
      window.dispatchEvent(
        new CustomEvent(TAB_EVENT, {
          detail: { ...INITIAL_CONFIG, open: false },
        }),
      )
    },
  }
}

const TAB_EVENT = 'vdi:tabulation'
