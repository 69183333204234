import { useController } from '@shared/form-provider'

import { type FormFieldOptions } from '../form-fields.types'
import { useFieldProps } from '../hooks'
import { SelectField } from '../select-field'
import { useReasons, useSubreasons } from './reason-field.hooks'

export const ControlledReasonField: React.FC<FormFieldOptions> = (props) => {
  const { reasons, handleChangeReason, hasReasons } = useReasons()

  const isDisabled = !hasReasons || props.disabled
  const fieldProps = useFieldProps(props)
  const controlled = useController(fieldProps.name, fieldProps.isRequired, '')

  return (
    <SelectField
      {...fieldProps}
      {...controlled}
      disabled={isDisabled}
      fieldOptions={reasons}
      onChange={(event) => {
        controlled.onChange(event)
        handleChangeReason(event.target.value)
      }}
    />
  )
}

export const ControlledSubreasonField: React.FC<FormFieldOptions> = (props) => {
  const { subreasons, hasSubreasons } = useSubreasons()

  const isDisabled = !hasSubreasons
  const isRequired = !isDisabled && props.isRequired
  const fieldProps = useFieldProps(props)
  const controlled = useController(fieldProps.name, isRequired, '')

  return (
    <SelectField
      {...fieldProps}
      {...controlled}
      isRequired={isRequired}
      disabled={isDisabled}
      fieldOptions={subreasons}
    />
  )
}
