import { forwardRef, useState, type ReactNode } from 'react'

import { Button } from '@grupoboticario/flora-react'
import { CrossCircleIcon } from '@grupoboticario/flora-react-icons'

import { Container, Message } from './alert.styles'

export interface AlertProps {
  status: 'alert' | 'success' | 'warning'
  message: ReactNode
  show?: boolean
  buttonText?: string
  onButtonClick?: () => void
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ message, status, show = false, buttonText, onButtonClick }, ref) => {
    const [showAlert, setShowAlert] = useState(show)

    if (!showAlert) return null

    return (
      <Container ref={ref} data-testid="tabulation-alert" role="alert" status={status}>
        <Button
          hierarchy="tertiary"
          styleSemantic="neutral"
          surfaceColor="dark"
          size="small"
          aria-label="Fechar alerta"
          onClick={() => setShowAlert(false)}
        >
          <CrossCircleIcon color="$error-standard" size="24px" />
        </Button>

        <Message size="bodySmallShortMedium" weight="medium" status={status}>
          {message}
        </Message>

        {buttonText && (
          <Button size="small" styleSemantic={status} onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </Container>
    )
  },
)

Alert.displayName = 'Alert'
