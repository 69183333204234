import { useController } from '@shared/form-provider'

import { type FormFieldDefault } from '../form-fields.types'
import { useFieldProps } from '../hooks'
import { Checkbox } from './checkbox'

export const ControlledCheckbox: React.FC<FormFieldDefault> = (props) => {
  const fieldProps = useFieldProps<FormFieldDefault>(props)
  const controlled = useController(fieldProps.name, fieldProps.isRequired)
  return <Checkbox {...fieldProps} {...controlled} />
}
