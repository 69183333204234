import { type TabulationConfig } from '../configuration/configuration.types'

export const createTabulationConfig = (): TabulationConfig => ({
  header: {
    title: '',
  },
  footer: {
    cancelButtonLabel: '',
    confirmButtonLabel: '',
  },
  status: {
    closeOnSuccess: true,
    errorDescription: '',
    loadingDescription: '',
    loadingTitle: '',
  },
  form: {
    reCodeLabel: '',
    reNameLabel: '',
    reCode: '',
    reName: '',
    requiredMessage: '',
  },
  api: {
    baseUrl: '',
    sendTabulationEndpoint: '',
    formFieldsEndpoint: '',
    reasonsEndpoint: '',
    timeout: 15000,
  },
})
