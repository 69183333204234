import type React from 'react'

import { Button, Spinner, Text } from '@grupoboticario/flora-react'

import { ptBR } from '../locales'

import { StatusSontainer, StatusTextGroup } from './tabulation-status.styles'

interface TabulationStatusProps extends TabulationErrorProps {
  loading: boolean
  error: boolean
}

export const TabulationStatus: React.FC<TabulationStatusProps> = ({ loading, error, onRetry }) => (
  <StatusSontainer>
    {loading && <Spinner data-testid="tab:first-loading" size="md" />}
    {error && <TabulationError onRetry={onRetry} />}
  </StatusSontainer>
)

interface TabulationErrorProps {
  onRetry: () => void
}

const TabulationError: React.FC<TabulationErrorProps> = ({ onRetry }) => (
  <>
    <StatusTextGroup data-testid="error">
      <Text size="subTitleMobileMedium" color="$nonInteractivePredominant" weight="medium">
        {ptBR.tabulationErrorTitle}
      </Text>
      <Text size="bodyMediumShort" color="$nonInteractiveAuxiliar">
        {ptBR.tabulationErrorDescription}
      </Text>
    </StatusTextGroup>
    <Button onClick={onRetry} hierarchy="secondary" size="small">
      {ptBR.tabulationErrorRetry}
    </Button>
  </>
)
