import { ModalHeader, styled } from '@grupoboticario/flora-react'

const StyledModalHeader = styled(ModalHeader, {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '$3 $5 !important',

  '@mobile': {
    '&::after': {
      content: '',
      position: 'absolute',
      right: '$5',
      left: '$5',
      bottom: 0,
      height: '1px',
      backgroundColor: '$nonInteractiveAltAuxiliar',
    },
  },

  h4: {
    marginBottom: 0,
  },
})

export { StyledModalHeader }
