import type React from 'react'
import { Fragment } from 'react'

import { Text } from '@grupoboticario/flora-react'
import { toLocaleDateTime } from '@grupoboticario/vdi-mfe-utils'

import { ptBR } from '../locales'
import { type GetLabelTab, type Tabulation, type TabulationUser } from '../tabulation-history.types'

import { useIsMobile } from '@shared/hooks'
import {
  TabulationFieldContainer,
  TabulationFieldGroup,
  TabulationItemContainer,
  TabulationItemHeaderContainer,
} from './tabulation-item.styles'

interface TabulationItemProps {
  bg?: string
  item: Tabulation
  headerText?: string
  headerRight?: React.ReactNode
  hideContactPurpose?: boolean
  getLabelTab?: GetLabelTab
}

export const TabulationItem: React.FC<TabulationItemProps> = ({
  bg = '$backgroundSecondary',
  item = {} as Tabulation,
  headerText,
  headerRight,
  hideContactPurpose = false,
  getLabelTab = getLabelTabDefault,
}) => {
  const { fields } = ptBR
  const labels = getLabelTab?.(item)
  const isMobile = useIsMobile()
  const isActivation = item.serviceType === 'activation'

  return (
    <Fragment>
      <TabulationItemHeaderContainer>
        <Text
          as="h3"
          color="$nonInteractivePredominant"
          size="bodyLargeStandardMedium"
          weight="medium"
        >
          {getHeaderText(item, headerText)}
        </Text>

        {!isMobile && headerRight}
      </TabulationItemHeaderContainer>

      <TabulationItemContainer css={{ backgroundColor: bg }}>
        <TabulationFieldGroup>
          <TabulationField {...labels.reason} />
          {!isActivation && <TabulationField {...labels.subreason} />}
        </TabulationFieldGroup>

        {!isActivation && (
          <TabulationFieldGroup>
            <TabulationField {...labels.note} />
          </TabulationFieldGroup>
        )}

        {!hideContactPurpose && (
          <TabulationFieldGroup>
            <TabulationField {...labels.contactPurpose} />
          </TabulationFieldGroup>
        )}

        {!isActivation && (
          <TabulationFieldGroup>
            <TabulationField {...labels.contactType} />
            <TabulationField {...labels.contactEffectiveness} />
          </TabulationFieldGroup>
        )}

        {!isActivation && (
          <TabulationFieldGroup>
            <TabulationField {...labels.contactChannel} />
          </TabulationFieldGroup>
        )}

        <TabulationFieldGroup>
          <TabulationField name={fields.owner} value={getOwner(item.users)} />
        </TabulationFieldGroup>
      </TabulationItemContainer>

      {isMobile && headerRight}
    </Fragment>
  )
}

interface TabulationFieldProps {
  name: string
  value?: string
}

const TabulationField: React.FC<TabulationFieldProps> = ({ name, value }) => (
  <TabulationFieldContainer>
    <Text
      as="h4"
      color="$nonInteractiveAuxiliar"
      size="exceptionsRestrictedMedium"
      weight="medium"
      css={{ textTransform: 'uppercase', letterSpacing: '0.08rem' }}
    >
      {name}
    </Text>

    <Text
      as="p"
      isTruncated
      color="$nonInteractivePredominant"
      size="bodyLargeShortMedium"
      weight="medium"
    >
      {value || '---'}
    </Text>
  </TabulationFieldContainer>
)

const getOwner = (users?: TabulationUser[]): string => {
  const user = users?.[0]
  if (!user?.name) return ''
  return `${user.name} - Código ${user.userId}`
}

const getHeaderText = (item: Tabulation, headerText?: string): string => {
  if (!item.createdAt) return ''

  const text = item.serviceType === 'activation' ? ptBR.activationIn : ptBR.attendedIn

  const attendedDate = toLocaleDateTime(item.createdAt, {
    format: (s: string) => s.replace(',', ' às'),
    locale: 'pt-BR',
    second: undefined,
    timeZone: 'America/Sao_Paulo',
  })

  return `${headerText ? headerText : text}${attendedDate}`
}

const getLabelTabDefault: GetLabelTab = (tab) => ({
  reason: { name: ptBR.fields.reason, value: tab.reason },
  subreason: { name: ptBR.fields.subreason, value: tab.subreason },
  note: { name: ptBR.fields.note, value: tab.note },
  contactPurpose: { name: ptBR.fields.contactPurpose, value: tab.contactPurpose },
  contactType: { name: ptBR.fields.contactType, value: tab.contactType },
  contactChannel: { name: ptBR.fields.contactChannel, value: tab.contactChannel },
  contactEffectiveness: {
    name: ptBR.fields.contactEffectiveness,
    value: tab.contactEffectiveness,
  },
})
