import type React from 'react'
import { PropsWithChildren, useRef } from 'react'

import { ScrollAreaStyled } from './scroll-area.styles'

interface ScrollAreaProps extends PropsWithChildren {
  height?: number
  onEndScroll?: () => void
}

export const ScrollArea: React.FC<ScrollAreaProps> = ({ onEndScroll, children }) => {
  const ref = useRef<HTMLDivElement>(null)

  const onScroll = (): void => {
    const scrollEl = ref.current as HTMLDivElement

    const { scrollTop, scrollHeight, clientHeight } = scrollEl
    const currentScrollTop = scrollTop + clientHeight

    if (currentScrollTop >= scrollHeight) {
      onEndScroll?.()
    }
  }

  return (
    <ScrollAreaStyled data-testid="scroll-area" ref={ref} onScroll={onScroll}>
      {children}
    </ScrollAreaStyled>
  )
}
