import { useTabulationConfig } from '@shared/configuration'

import { Text } from '@grupoboticario/flora-react'

import { StyledModalHeader } from './modal-header.styles'

const ModalHeader = (): JSX.Element => {
  const { header } = useTabulationConfig()

  return (
    <StyledModalHeader data-testid="tabulation-modal-header">
      <Text as="h4" size="subTitleDeskMedium" weight="medium">
        {header.title}
      </Text>
    </StyledModalHeader>
  )
}

export { ModalHeader }
