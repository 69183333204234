import { styled } from '@grupoboticario/flora-react'

export const FormContainer = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',

  variants: {
    center: {
      true: {
        justifyContent: 'center',
      },
      false: {},
    },
  },
})
