import type React from 'react'
import { PropsWithChildren } from 'react'

import { Text } from '@grupoboticario/flora-react'
import { ExclamationMarkHexagonFillIcon } from '@grupoboticario/flora-react-icons'

import { FieldContainer, FieldErrorMessage, FieldTitleContainer } from './field-info.styles'

interface FieldInfoProps extends PropsWithChildren {
  error?: string
  disabled?: boolean
  title?: string
}

export const FieldInfo: React.FC<FieldInfoProps> = ({ error, title, disabled, children }) => (
  <FieldContainer>
    {title && <FieldTitle title={title} disabled={disabled} />}
    {children}
    {error && <FieldError error={error} />}
  </FieldContainer>
)

const FieldTitle: React.FC<FieldInfoProps> = ({ title, disabled }) => (
  <FieldTitleContainer>
    <Text
      as="h5"
      color={disabled ? '$nonInteractiveAuxiliar' : '$nonInteractivePredominant'}
      size="bodySmallShortMedium"
      weight="medium"
    >
      {title}
    </Text>
  </FieldTitleContainer>
)

const FieldError: React.FC<{ error: string }> = ({ error }) => (
  <FieldErrorMessage color="$nonInteractivePredominant">
    <ExclamationMarkHexagonFillIcon size="14px" color="$error-standard" />
    {error}
  </FieldErrorMessage>
)
