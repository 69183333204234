import { useContext, useMemo } from 'react'
import {
  useController as useHookController,
  useFormContext as useHookFormContext,
  useFormState as useHookFormState,
  type ControllerRenderProps,
  type FieldValues,
  type UseFormClearErrors,
  type UseFormGetValues,
  type UseFormHandleSubmit,
  type UseFormReset,
  type UseFormSetValue,
} from 'react-hook-form'

import { useTabulationConfig } from '../configuration'
import { FormStatusContext, type FormStatusContextProps } from './form-provider'

interface UseController extends ControllerRenderProps<FieldValues, string> {
  invalidText?: string
}

export const useController = (
  name: string,
  isRequired?: boolean,
  defaultValue?: unknown,
): UseController => {
  const { control } = useHookFormContext()
  const { form } = useTabulationConfig()

  const {
    field,
    fieldState: { error },
  } = useHookController({
    control,
    name,
    rules: { required: isRequired ? form.requiredMessage : false },
    defaultValue,
  })

  return {
    ...field,
    invalidText: error?.message as string,
  }
}

interface UseFormStateHandler {
  changeValue: UseFormSetValue<FieldValues>
  changeErrors: UseFormClearErrors<FieldValues>
  changeValues: UseFormReset<FieldValues>
  handleSubmit: UseFormHandleSubmit<FieldValues, undefined>
  getValues: UseFormGetValues<FieldValues>
}

export const useFormHandler = (): UseFormStateHandler => {
  const { setValue, clearErrors, reset, handleSubmit, getValues } = useHookFormContext()

  return useMemo(
    () => ({
      changeValue: setValue,
      changeErrors: clearErrors,
      changeValues: reset,
      handleSubmit,
      getValues,
    }),
    [setValue, clearErrors, reset, handleSubmit, getValues],
  )
}

export const useFormValid = (): boolean => {
  const { isValid } = useHookFormState()
  return isValid
}

export const useFormStatus = (): FormStatusContextProps => {
  const context = useContext(FormStatusContext)
  if (!context) throw new Error('Context not provided')
  return context
}
