import { useContext } from 'react'

import { useFormHandler } from '@shared/form-provider'

import { type FormFieldOption } from '../form-fields.types'
import { ReasonsContext, type ReasonProviderProps } from './reason-field.provider'
import { ReasonFieldType } from './reason-field.types'

export interface UseReasons {
  reasons: FormFieldOption[]
  hasReasons: boolean
  handleChangeReason: (reasonId: string) => void
}

export function useReasons(): UseReasons {
  const { reasons, setSubreasons } = useReasonProvider()
  const { changeErrors, changeValue } = useFormHandler()

  const handleChangeReason = (reasonId: string): void => {
    setSubreasons(reasonId)
    changeValue(ReasonFieldType.subreason, '')
    changeErrors(ReasonFieldType.subreason)
  }

  return {
    reasons,
    handleChangeReason,
    hasReasons: reasons.length > 0,
  }
}

interface UseSubresons {
  subreasons: FormFieldOption[]
  hasSubreasons: boolean
}

export const useSubreasons = (): UseSubresons => {
  const { subreasons } = useReasonProvider()
  const hasSubreasons = subreasons.length > 0
  return { subreasons, hasSubreasons }
}

export function useReasonProvider(): ReasonProviderProps {
  const context = useContext(ReasonsContext)
  if (!context) throw new Error('No context provided')
  return context
}
