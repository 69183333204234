import type React from 'react'
import { Fragment, useEffect, useRef, type ReactNode } from 'react'

import { Button, Flex, Spinner, Text } from '@grupoboticario/flora-react'
import { CheckCircleFillIcon } from '@grupoboticario/flora-react-icons'

import { type AsyncStatus } from '@shared/api'
import { Alert } from '@shared/components'
import { useTabulationConfig } from '@shared/configuration'
import { reloadPage } from '@shared/utils'

import { TabulationErrorType } from '../../form-tabulation.types'
import { ptBR } from '../../locales'
import { Container, Description, Title } from './form-status.styles'

interface FormStatusProps extends AsyncStatus, FormSuccessProps, FormErrorProps {}

export const FormStatus: React.FC<FormStatusProps> = ({ loading, error, filled, ...others }) => (
  <Fragment>
    {filled && <FormSuccess {...others} />}
    {error && <FormError {...others} />}
    {loading && <FormLoading />}
  </Fragment>
)

interface FormSuccessProps {
  onFinaly: () => void
}

const FormSuccess: React.FC<FormSuccessProps> = ({ onFinaly }) => {
  const { status } = useTabulationConfig()

  useEffect(() => {
    if (status.closeOnSuccess) {
      onFinaly()
    }
  }, [])

  if (status.closeOnSuccess) return null

  return (
    <Container data-testid="tabulation-success-message">
      <CheckCircleFillIcon color="$success-standard" size={56} />

      <Title as="h5">
        {status.successTitle}
        <Description as="small">{status.successDescription}</Description>
      </Title>

      <Button hierarchy="primary" styleSemantic="success" onClick={onFinaly}>
        {status.successButtonLabel}
      </Button>
    </Container>
  )
}

const FormLoading: React.FC = () => {
  const { status } = useTabulationConfig()

  return (
    <Container>
      <Flex direction="column" gap="$4" align="center" justify="center">
        <Flex direction="column" gap="$2" align="center" justify="center">
          <Text
            as="h5"
            size="subTitleDeskMedium"
            color="$nonInteractivePredominant"
            weight="medium"
          >
            {status.loadingTitle}
          </Text>

          <Text size="bodySmallShortMedium" color="$nonInteractiveAuxiliar" weight="medium">
            {status.loadingDescription}
          </Text>
        </Flex>

        <Spinner size="md" color="$9" />
      </Flex>
    </Container>
  )
}

interface FormErrorProps {
  errorType: TabulationErrorType
  onRetry: (...args: unknown[]) => void
}

const FormError: React.FC<FormErrorProps> = ({ errorType, onRetry }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.focus()
  }, [])

  const error = errorMessage[errorType](onRetry)

  return (
    <Alert
      show
      ref={ref}
      status="warning"
      buttonText={error.buttonText}
      onButtonClick={error.onClick}
      message={error.message}
    />
  )
}

type FormErrorObject = Record<
  TabulationErrorType,
  (onRetry: (...args: unknown[]) => void) => {
    onClick: () => void
    buttonText: string
    message: ReactNode
  }
>

const errorMessage: FormErrorObject = {
  [TabulationErrorType.unknown]: (onRetry: () => void) => ({
    onClick: onRetry,
    buttonText: ptBR.tabulation.error.tryAgain_cap,
    message: (
      <span>
        {ptBR.tabulation.error.unknown}{' '}
        <span className="alert-err-action" onClick={onRetry}>
          {ptBR.tabulation.error.tryAgain}
        </span>
      </span>
    ),
  }),
  [TabulationErrorType.closedBySystem]: () => ({
    onClick: reloadPage,
    buttonText: ptBR.tabulation.error.update,
    message: (
      <span>
        {ptBR.tabulation.error.closeBySystem}{' '}
        <span className="alert-err-action" onClick={reloadPage}>
          {ptBR.tabulation.error.updatePage}
        </span>
      </span>
    ),
  }),
  [TabulationErrorType.closed]: () => ({
    onClick: reloadPage,
    buttonText: ptBR.tabulation.error.update,
    message: (
      <span>
        {ptBR.tabulation.error.closed}{' '}
        <span className="alert-err-action" onClick={reloadPage}>
          {ptBR.tabulation.error.updatePage}
        </span>
      </span>
    ),
  }),
  [TabulationErrorType.notExists]: () => ({
    onClick: reloadPage,
    buttonText: ptBR.tabulation.error.update,
    message: (
      <span>
        {ptBR.tabulation.error.notExists}{' '}
        <span className="alert-err-action" onClick={reloadPage}>
          {ptBR.tabulation.error.updatePage}
        </span>
      </span>
    ),
  }),
  [TabulationErrorType.expired]: () => ({
    buttonText: '',
    onClick: () => {},
    message: ptBR.tabulation.error.expired,
  }),
}
