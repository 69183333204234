import { type IHttpResult } from '@grupoboticario/vdi-mfe-utils'

export interface API {
  getFormFields: <R>() => Promise<IHttpResult<R, unknown>>
  getReasons: <R>() => Promise<IHttpResult<R, unknown>>
  getTabulations: <R>(page: number, limit: number) => Promise<IHttpResult<R, unknown>>
  sendTabulation: <R, B>(tabulation: B) => Promise<IHttpResult<R, unknown>>
}

export enum MetaState {
  Idle,
  Loading,
  Error,
  Fullfilled,
}

export interface AsyncStatus {
  filled: boolean
  error: boolean
  loading: boolean
}
