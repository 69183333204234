import { Button, Flex, Heading, styled, Text } from '@grupoboticario/flora-react'

export const SuccessButton = styled(Button, {
  width: '295px',
})

export const Container = styled(Flex, {
  width: '100%',
  alignItems: 'center',
  margin: '0 auto',
  height: '400px',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
})

export const Description = styled(Text, {
  display: 'block',
  color: '$nonInteractiveAuxiliar',
  fontSize: '$bodySmall',
  fontWeight: '$medium',
  lineHeight: '$bodyLarge',
  paddingTop: '$2',
})

export const Title = styled(Heading, {
  color: '$nonInteractivePredominant',
  fontSize: '$bodyLarge',
  fontWeight: '$medium',
  lineHeight: '$xsubtitleMobile',
  textAlign: 'center',
  padding: '$8 0',
})
