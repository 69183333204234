import { useController } from '@shared/form-provider'

import { type FormFieldDefault } from '../form-fields.types'
import { useFieldProps } from '../hooks'
import { TextField } from './text-field'

interface ControllerTextField extends FormFieldDefault {
  as: 'input' | 'textarea'
}

export const ControlledTextField: React.FC<ControllerTextField> = (props) => {
  const fieldProps = useFieldProps(props)
  const controlled = useController(fieldProps.name, fieldProps.isRequired, '')
  return <TextField {...fieldProps} {...controlled} />
}
