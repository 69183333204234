import { styled } from '@grupoboticario/flora-react'

export const ScrollAreaStyled = styled('div', {
  height: '100%',
  overflowY: 'auto',
  scrollbarWidth: 'auto',
  scrollbarColor: 'rgb(38, 79, 236) transparent',

  '&:-webkit-scrollbar': {
    width: '10px',
  },

  '&:-webkit-scrollbar-track': {
    background: 'transparent',
  },

  '&:-webkit-scrollbar-thumb': {
    backgroundColor: 'rgb(38, 79, 236)',
    borderRadius: '20px',
    border: '3px solid transparent',
  },
})
