/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { GtmTracker } from '@vdi/event-tracker'

const defaultEvent = {
  event: 'event',
}

const CATEGORY_PREFIX = 'vd-interativa:atendimento:'

interface Events {
  cancelTab: (reCode: string, value: string) => void
  viewHistory: (reId?: string, attendanceId?: string) => void
  paginateHistory: (page: number) => void
}

export const useEvents = (): Events => ({
  cancelTab: (reCode, value) =>
    GtmTracker.track({
      ...defaultEvent,
      label: `cancelar-${reCode}-${value}`,
      category: `${CATEGORY_PREFIX}tabulacao`,
      action: 'clique:botao',
    }),
  viewHistory: (reId, attendanceId) => {
    GtmTracker.track({
      ...defaultEvent,
      label: `ver_historico_completo`,
      category: `${CATEGORY_PREFIX}visao-geral`,
      action: 'clique:botao',
    })
    GtmTracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: 'interaction_atendimento',
        cd_interaction_detail: 'click:historico-completo',
        cd_codigo_atendimento: attendanceId,
        cd_codigo_representante: reId,
        cd_section: 'visao-geral:historico-de-tabulacoes',
      },
    })
  },
  paginateHistory: (page: number) =>
    GtmTracker.track({
      ...defaultEvent,
      label: `mostrar_mais_resultados_${page}`,
      category: `${CATEGORY_PREFIX}visao-geral`,
      action: 'clique:botao',
    }),
})
