import { HttpClient, HttpMethod } from '@grupoboticario/vdi-mfe-utils'

import { authService } from '@vdi/auth-service'

import {
  type TabulationConfigApi,
  type TabulationConfigApiForm,
  type TabulationConfigApiHistory,
} from '@shared/configuration'

import { type API } from './api.types'

export const createApi = (configApi: TabulationConfigApi): API => {
  const config = createApiConfig(configApi)

  const http = HttpClient.create({
    baseUrl: config.baseUrl,
    timeout: config.timeout ?? 15000,
    getHeaders,
  })

  return {
    getFormFields: () => http.query({ endpoint: config.formFieldsEndpoint }),
    getReasons: () => http.query({ endpoint: config.reasonsEndpoint }),
    getTabulations: (page, limit) =>
      http.query({ endpoint: config.historyEndpoint, params: { page, limit } }),
    sendTabulation: (tabulation) =>
      http.mutate({
        endpoint: config.sendTabulationEndpoint,
        method: HttpMethod.Post,
        body: tabulation,
      }),
  }
}

const createApiConfig = (
  config: TabulationConfigApi,
): TabulationConfigApiForm & TabulationConfigApiHistory => ({
  timeout: 15000,
  sendTabulationEndpoint: '',
  formFieldsEndpoint: '',
  reasonsEndpoint: '',
  historyEndpoint: '',
  ...config,
})

const getHeaders = async (): Promise<Record<string, string>> => {
  const { token } = await authService.getTokenOrRedirect()
  return {
    authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  }
}
