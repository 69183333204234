import { ModalContent as ModalContentFlora, styled } from '@grupoboticario/flora-react'

const ModalContent = styled(ModalContentFlora, {
  borderRadius: '0 !important',
  height: '100% !important',
  width: '100% !important',

  '@mobile': {
    borderRadius: '$medium !important',
    height: '600px !important',
    width: '500px !important',
    paddingTop: '$3',
    paddingBottom: '$3',
  },
})

export { ModalContent }
