import type React from 'react'
import { PropsWithChildren } from 'react'

import { ScrollArea } from '@shared/components'

import { ModalBodyStyled } from './modal-body.styles'

export const ModalBody: React.FC<PropsWithChildren> = ({ children }) => (
  <ModalBodyStyled>
    <ScrollArea>{children}</ScrollArea>
  </ModalBodyStyled>
)
