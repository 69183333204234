import { styled } from '@grupoboticario/flora-react'

export const StatusSontainer = styled('div', {
  minHeight: 315,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: '$medium',
  background: '$backgroundPrimary',
  gap: '$6',
})

export const StatusTextGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$2',
})
