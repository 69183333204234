import { Radio, styled, Text } from '@grupoboticario/flora-react'

const StyledRadio = styled(Radio, {
  '&:focus-visible': {
    '~ label': {
      '&::after': {
        transform: 'scale(1)',
      },
    },
  },
})

const Label = styled(Text, {
  position: 'relative',
  cursor: 'pointer',

  '&::after': {
    content: '',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '$actionableDefault',
    transform: 'scale(0)',
    transition: 'transform .2s ease-out',
  },
})

export { Label, StyledRadio }
