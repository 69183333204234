import { forwardRef, Fragment } from 'react'

import { Dropdown, DropdownItem } from '@grupoboticario/flora-react'

import { FieldInfo } from '../field-info'
import { type FormFieldControl, type FormFieldOptions } from '../form-fields.types'

export interface SelectFieldProps extends FormFieldOptions, FormFieldControl<string> {}

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      fieldOptions,
      isRequired,
      invalidText,
      onChange,
      labelText,
      value,
      isVisible,
      placeholder = '',
      ...props
    },
    ref,
  ) => {
    const invalid = Boolean(invalidText)

    const handleChange = (value: string) => () => {
      onChange({ target: { value }, type: 'change' })
    }

    const renderValue = (): string | undefined => fieldOptions.find((o) => o.value === value)?.label

    return (
      <Fragment>
        {isVisible && (
          <FieldInfo disabled={props.disabled} title={labelText}>
            <Dropdown
              ref={ref}
              invalid={invalid}
              required={isRequired}
              invalidText={invalidText}
              renderValue={renderValue}
              labelText={placeholder}
              onChange={(event) => handleChange(event.target.value)()}
              css={{
                '> option': {
                  color: '$nonInteractivePredominant',
                },
              }}
              {...props}
            >
              {fieldOptions.map(({ label, value, disabled }) => (
                <DropdownItem
                  key={value}
                  disabled={disabled}
                  value={value}
                  label={label}
                  onClick={handleChange(value)}
                >
                  {label}
                </DropdownItem>
              ))}
            </Dropdown>
          </FieldInfo>
        )}
      </Fragment>
    )
  },
)

SelectField.displayName = 'SelectField'
