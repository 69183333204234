import { Text, styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  borderRadius: '$medium',
  padding: '$6 $6 $5 $5',
  alignItems: 'center',
  gap: '$5',

  '&:focus-visible': {
    outline: '2px solid indianred',
    outlineOffset: '5px',
  },

  variants: {
    status: {
      alert: {},
      success: {},
      warning: {
        backgroundColor: '$statusErrorBackgroundDefault',
      },
    },
  },
})

const Message = styled(Text, {
  '& .alert-err-action': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  variants: {
    status: {
      alert: {},
      success: {},
      warning: {
        color: '$error-standard',
      },
    },
  },
})

export { Container, Message }
