export interface FormField {
  id: string
  name: string
  labelText: string
  disabled?: boolean
  optionalText?: string
  placeholder?: string
  invalidText?: string
  isRequired?: boolean
  isVisible?: boolean
}

export interface FormFieldDefault extends FormField {
  formFieldType?: FieldType.checkbox | FieldType.text | FieldType.textarea
}

export interface FormFieldOptions extends FormField {
  formFieldType?: FieldType.radio | FieldType.select | FieldType.radioButton
  fieldOptions: FormFieldOption[]
}

export interface FormFieldOption {
  id: string
  label: string
  value: string
  disabled?: boolean
  default?: boolean
}

export interface FormFieldControl<T> {
  onChange: FormFieldChangeHandler<T>
  value?: T
}

export type FormFieldChangeHandler<T> = (event: FormFieldEvent<T>) => void

interface FormFieldEvent<T> {
  target: { value: T }
  type: string
}

export enum FieldType {
  text = 'text',
  radio = 'radio',
  select = 'select',
  textarea = 'textArea',
  checkbox = 'checkbox',
  radioButton = 'radioButton',
}

export type FormFieldDTO = FormFieldTypes & {
  isInitiallyDisabled?: boolean
  placeHolder?: string
}

export interface GetFormFieldsDTO {
  formFields: FormFieldDTO[]
  conditions: FormFieldCondition[]
}

export type FormFieldTypes = FormFieldDefault | FormFieldOptions

export interface FormFieldCondition {
  id: string
  for: string
  when: string
  and?: ConditionAnd[]
  then: ConditionRule[]
}

export interface ConditionAnd {
  for: string
  when: string | boolean
}

export interface ConditionRule {
  for: string
  value?: string | boolean
  disabled?: boolean
  isRequired?: boolean
  filterReasons?: string[]
}
