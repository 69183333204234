import type React from 'react'
import { forwardRef, Fragment } from 'react'

import { Flex, RadioGroup as RadioGroupFlora, Text } from '@grupoboticario/flora-react'

import { FieldInfo } from '../field-info'
import {
  type FormFieldControl,
  type FormFieldOption,
  type FormFieldOptions,
} from '../form-fields.types'

import { Label, StyledRadio } from './radio-group.styles'

export interface RadioGroupProps extends FormFieldOptions, FormFieldControl<string> {}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      id,
      name,
      labelText,
      fieldOptions,
      isRequired,
      onChange,
      disabled,
      invalidText,
      isVisible,
      value,
      ...props
    },
    ref,
  ) => (
    <Fragment>
      {isVisible && (
        <FieldInfo error={invalidText} disabled={disabled} title={labelText}>
          <RadioGroupFlora
            id={id}
            ref={ref}
            name={name}
            value={value}
            required={isRequired}
            variant="standard"
            onValueChange={(value) => onChange({ target: { value }, type: 'change' })}
            {...props}
          >
            <Flex wrap="wrap" align="center" gap="$3 $11">
              {fieldOptions.map((opt, idx) => (
                <RadioItem
                  key={idx}
                  disabled={disabled}
                  invalid={Boolean(invalidText)}
                  {...opt}
                  id={id}
                />
              ))}
            </Flex>
          </RadioGroupFlora>
        </FieldInfo>
      )}
    </Fragment>
  ),
)

RadioGroup.displayName = 'RadioGroup'

interface RadioItemProps extends FormFieldOption {
  id: string
  invalid: boolean
}

const RadioItem: React.FC<RadioItemProps> = ({ label, disabled, value, id, invalid }) => {
  const fieldId = `${id}-${value}`

  return (
    <Flex align="center" gap="$2">
      <StyledRadio id={fieldId} aria-invalid={invalid} disabled={disabled} value={value} />

      <Label as="label" htmlFor={fieldId}>
        <Text
          as="span"
          size="bodySmallStandardRegular"
          color={disabled ? '$disabledContent' : '$nonInteractiveAuxiliar'}
        >
          {label}
        </Text>
      </Label>
    </Flex>
  )
}
