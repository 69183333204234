import type React from 'react'
import { useCallback } from 'react'
import { createRoot } from 'react-dom/client'

import { FloraProvider, Modal, ModalCloseButton } from '@grupoboticario/flora-react'

import { ApiProvider, createApi } from '@shared/api'
import { ConfigurationProvider } from '@shared/configuration'
import { FormProvider, useFormStatus } from '@shared/form-provider'

import { FormTabulation } from '../form-tabulation'
import { ModalBody } from './modal-body'
import { ModalContent } from './modal-content'
import { ModalFooter } from './modal-footer'
import { useModalTabulationConfig } from './modal-tabulation.hooks'
import { ModalHeader } from './model-header'

export const ModalRoot: React.FC = () => {
  const { config, onOpenChange } = useModalTabulationConfig()

  return (
    <FloraProvider>
      <ConfigurationProvider config={config}>
        <ApiProvider api={createApi(config.api)}>
          <FormProvider>
            {config.open && <ModalTabulation open onOpenChange={onOpenChange} />}
          </FormProvider>
        </ApiProvider>
      </ConfigurationProvider>
    </FloraProvider>
  )
}

interface ModalTabulationProps {
  onOpenChange: () => void
  open: boolean
}

const ModalTabulation: React.FC<ModalTabulationProps> = ({ open, onOpenChange }) => {
  const [{ loading, filled, error }] = useFormStatus()

  const handleOpenChange = useCallback((): void => {
    if (!loading) {
      onOpenChange()
    }
  }, [loading, onOpenChange])

  return (
    <Modal open={open} onOpenChange={handleOpenChange}>
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />

        <ModalBody data-testid="tab:modal-content">
          <FormTabulation onFinaly={onOpenChange} />
        </ModalBody>

        {!filled && <ModalFooter loading={loading} error={error} onOpenChange={handleOpenChange} />}
      </ModalContent>
    </Modal>
  )
}

export const renderModalRoot = (): void => {
  let container = document.getElementById(TAB_CONTAINER_ID)

  if (!container) {
    container = document.createElement('div')
    container.setAttribute('id', TAB_CONTAINER_ID)
    document.body.appendChild(container)
    const root = createRoot(container)
    root.render(<ModalRoot />)
  }
}

const TAB_CONTAINER_ID = 'vdi-application:tabulation-modal'
