import { useContext } from 'react'

import { ApiContext } from './api.provider'
import { type API } from './api.types'

export const useApi = (): API => {
  const context = useContext(ApiContext)
  if (!context) throw new Error('Context not provided')
  return context
}
