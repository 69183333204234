import type React from 'react'

import { Flex, Text } from '@grupoboticario/flora-react'
import { useTabulationConfig } from '@shared/configuration'

import { useIsMobile } from '@shared/hooks'
import { InfoContainer, InfoGroup } from './form-reseller-info.styles'

export const FormResellerInfo: React.FC = () => {
  const { form } = useTabulationConfig()
  const isMobile = useIsMobile()

  return (
    <Flex direction="column" gap="$4">
      <InfoContainer>
        <FormResellerInfoSection fill label={form.reNameLabel} value={form.reName} />
        {!isMobile && <FormResellerInfoSection label={form.reCodeLabel} value={form.reCode} />}
      </InfoContainer>
      {isMobile && (
        <InfoContainer>
          <FormResellerInfoSection label={form.reCodeLabel} value={form.reCode} />
        </InfoContainer>
      )}
    </Flex>
  )
}

interface FormResellerInfoSectionProps {
  label: string
  value: string
  fill?: boolean
}

const FormResellerInfoSection: React.FC<FormResellerInfoSectionProps> = ({
  label,
  value,
  fill,
}) => (
  <InfoGroup fill={fill}>
    <Text as="h4" size="bodyLargeShortMedium" color="$nonInteractiveAuxiliar" weight="medium">
      {label}
    </Text>

    <Text as="p" size="bodyLargeStandardMedium" color="$nonInteractivePredominant" weight="medium">
      {value}
    </Text>
  </InfoGroup>
)
