import { Spinner } from '@grupoboticario/flora-react'

import { useTabulationConfig } from '@shared/configuration'
import { FORM_TAB_ID } from '@shared/consts'
import { useEvents } from '@shared/events'
import { useFormHandler, useFormValid } from '@shared/form-provider'

import { useIsMobile } from '@shared/hooks'
import { Container, StyledButton } from './modal-footer.styles'

interface ModalFooterProps {
  onOpenChange: () => void
  error: boolean
  loading: boolean
}

const ModalFooter = ({ onOpenChange, error, loading }: ModalFooterProps): JSX.Element => {
  const { footer, form } = useTabulationConfig()
  const { getValues } = useFormHandler()
  const isMobile = useIsMobile()
  const events = useEvents()
  const valid = useFormValid()

  const handleCancel = (): void => {
    const value = getValues('contactEffectiveness')
    events.cancelTab(form.reCode, value)
    footer.onCancel?.(getValues())
    onOpenChange()
  }

  return (
    <Container data-testid="tabulation-modal-footer">
      <StyledButton
        hierarchy="secondary"
        styleSemantic="neutral"
        size={isMobile ? 'medium' : 'small'}
        onClick={handleCancel}
        aria-label="Cancelar e fechar modal"
        isFull={isMobile}
      >
        {footer.cancelButtonLabel}
      </StyledButton>

      <StyledButton
        hierarchy="primary"
        form={FORM_TAB_ID}
        type="submit"
        size={isMobile ? 'medium' : 'small'}
        aria-label="Registrar atendimento"
        disabled={(!valid && !error) || loading}
        isFull={isMobile}
      >
        {!loading && footer.confirmButtonLabel}
        {loading && <Spinner />}
      </StyledButton>
    </Container>
  )
}

export { ModalFooter }
