import { styled } from '@grupoboticario/flora-react'

export const RadioItemContainer = styled('div', {
  button: {
    display: 'none',
  },
})

export const Label = styled('label', {
  position: 'relative',
  color: '$backgrounPrimary',
  cursor: 'pointer',
  border: '1px solid $actionableDefault',
  borderRadius: '$small',
  padding: '$1 $3',
  height: '$8',
  width: '100%',
  textAlign: 'center',

  '@mobile': {
    textAlign: 'left',
    width: 'initial',
  },

  variants: {
    invalid: {
      true: {
        border: '1px solid $error-standard',
        span: {
          color: '$error-standard',
        },
      },
    },

    selected: {
      true: {
        border: '1px solid $actionableDefault',
        background: '$actionableDefault',
        span: {
          color: '$nonInteractiveAltPredominant',
        },
      },
    },

    disabled: {
      true: {
        cursor: 'not-allowed',
        background: '$disabledBackground !important',
        border: '1px solid $disabledBackground !important',
        span: {
          color: '$disabledContent !important',
        },
      },
    },
  },
})
