import {
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  styled,
} from '@grupoboticario/flora-react'

export const DrawerCloseButtonStyled = styled(DrawerCloseButton, {
  right: '$4',
  top: '$3',

  '@mobile': {
    right: '$7',
    top: '$6',
  },
})

export const DrawerHeaderStyled = styled(DrawerHeader, {
  padding: '$4',
  borderBottom: '1px solid $nonInteractiveAltAuxiliar',

  '@mobile': {
    padding: '0 0 $4 0',
    margin: '$6 $7 0 $6',
  },
})

export const DrawerBodyStyled = styled(DrawerBody, {
  height: '100%',
  padding: '$2 $4 $6 $6',
})

export const DrawerContentStyled = styled(DrawerContent, {
  width: '100%',

  '@mobile': {
    width: '650px !important',
  },
})
