import { useEffect, useRef, type Dispatch, type SetStateAction } from 'react'

import { useFormHandler } from '@shared/form-provider'

import {
  type ConditionAnd,
  type FormFieldCondition,
  type FormFieldOption,
  type FormFieldTypes,
} from '../form-fields.types'
import { ReasonFieldType, useReasonProvider } from '../reason-field'

type FieldValue = string | boolean

type FieldState = FormFieldTypes & { value: FieldValue }

interface FormState {
  reasons: Set<string>
  fields: Map<string, FieldState>
}

interface UseConditions {
  onFieldChange: (id: string, value: string) => void
}

export const useConditions = (
  fields: FormFieldTypes[],
  setFields: Dispatch<SetStateAction<FormFieldTypes[]>>,
  conditions: FormFieldCondition[],
): UseConditions => {
  const formHandler = useFormHandler()
  const reasons = useReasonProvider()
  const cacheRef = useRef(new Map())
  const { current: cache } = cacheRef

  useEffect(() => {
    const mappedConditions = conditions.map((c, i) => ({ ...c, id: `condition-${i}` }))
    cacheRef.current.set('conditions', mappedConditions)
    cacheRef.current.set('initial_fields', fields)
    // só precisamos das condições atualizadas
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions])

  const onFieldChange = (fieldId: string, fieldValue: FieldValue): void => {
    const [matched, applied] = getFieldConditions(fieldId, fieldValue)

    const hasApplied = Boolean(applied)
    const hasMatched = Boolean(matched.length)

    if (!hasApplied && !hasMatched) return

    const form = createFormState()

    if (hasApplied) {
      revertCondition(applied, form)
    }

    if (hasMatched) {
      applyConditions(matched, form)
    }

    const updated = [...form.fields.values()]
    setFields(updated)
    setValues(updated.map((f) => [f.name, f.value]))
    setReasons(reasons.initials.filter((r) => form.reasons.has(r.id)))
  }

  const revertCondition = (condition: FormFieldCondition, form: FormState): void => {
    condition.then.forEach((rule) => {
      const initials: FormFieldTypes[] = cache.get('initial_fields')
      const initial = initials.find((i) => i.id === rule.for)
      const current = form.fields.get(rule.for)

      if (!current || !initial) return

      if (typeof rule.disabled === 'boolean') {
        current.disabled = initial.disabled
        current.isRequired = initial.isRequired
      }

      if (rule.value) {
        current.value = ''
        const applied = getFieldConditions(rule.for, rule.value)[1]
        if (applied) revertCondition(applied, form)
      }

      if (rule.filterReasons?.length) form.reasons.clear()
    })
  }

  const applyConditions = (conditions: FormFieldCondition[], form: FormState): void => {
    conditions.forEach((cond) => {
      cache.set(cond.for, cond)

      cond.then.forEach((rule) => {
        const current = form.fields.get(rule.for)

        if (!current) return

        if (typeof rule.disabled === 'boolean') {
          current.disabled = rule.disabled
          current.isRequired = rule.disabled ? false : current.isRequired
          if (!rule.value) current.value = ''
        }

        if (rule.value) {
          current.value = rule.value
          const matched = getFieldConditions(rule.for, rule.value)[0]
          if (matched.length) applyConditions(matched, form)
        }

        if (rule.filterReasons?.length) {
          form.reasons.clear()
          rule.filterReasons.forEach((r) => form.reasons.add(r))
        }
      })
    })
  }

  const getFieldConditions = (
    fieldId: string,
    fieldValue: FieldValue,
  ): [FormFieldCondition[], FormFieldCondition] => {
    const conditions: FormFieldCondition[] = cache.get('conditions')
    const applied: FormFieldCondition = cache.get(fieldId)

    const matched = conditions.filter((c) => {
      const isForField = c.for === fieldId
      const isForValue = c.when === fieldValue
      const isAndSatisfied = c.and?.every(satisfies) ?? true
      return isForField && isForValue && isAndSatisfied
    })

    return [matched, applied]
  }

  const satisfies = (and: ConditionAnd): boolean => {
    const field = fields.find((f) => f.id === and.for)
    if (!field) return true
    return and.when === formHandler.getValues(field.name)
  }

  const setReasons = (rsn: FormFieldOption[] = []): void => {
    reasons.setReasons(rsn)
    reasons.setSubreasons('')

    setValues([
      [ReasonFieldType.reason, ''],
      [ReasonFieldType.subreason, ''],
    ])
  }

  const setValues = (values: Array<[string, FieldValue]>): void => {
    values.forEach(([name, value]) => formHandler.changeValue(name, value))
  }

  const createFormState = (): FormState => ({
    reasons: new Set<string>(reasons.reasons.map((r) => r.id)),
    fields: new Map(fields.map((f) => [f.id, { ...f, value: formHandler.getValues(f.name) }])),
  })

  return { onFieldChange }
}
