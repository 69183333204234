import { styled, Text } from '@grupoboticario/flora-react'

export const FieldErrorMessage = styled(Text, {
  color: '$nonInteractivePredominant',
  display: 'flex',
  alignItems: 'center',
  svg: { marginRight: '$1', height: 16 },
  marginTop: '$1',
  fontSize: '$sm',
  lineHeight: '$base',
  height: '$4',
})

export const FieldTitleContainer = styled('div', {
  paddingBottom: '$2',

  '*': {
    margin: 0,
  },
})

export const FieldContainer = styled('div', {
  paddingBottom: '$1',

  '& [data-flora="input-wrapper"]': {
    minHeight: 'unset',
  },
})
